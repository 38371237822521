import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/templates/blog-post.js"

export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = DefaultLayout
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             Layout={this.layout} layoutProps={props}
             components={components}>

<MDXTag name="p" components={components}>{`I recently decided to switch the engine of
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://boardgamelab.app/"}}>{`Boardgame Lab`}</MDXTag>{` from TypeScript
to Rust. The application itself is an SPA written in Svelte.
I only switched the logic that updates the game state to Rust.
Here is a summary of my experience with the transition:`}</MDXTag>
<MDXTag name="h2" components={components}>{`Isomorphic Architecture`}</MDXTag>
<MDXTag name="p" components={components}>{`One of the advantages of using JavaScript or TypeScript is
that you can run the same code on both client and server.
For `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://boardgamelab.app/"}}>{`Boardgame Lab`}</MDXTag>{`, this means
that the game state can be updated independently on both sides.
This results in a lag-free experience on the client
while still using the server as the authoritative source of
data.`}</MDXTag>
<MDXTag name="p" components={components}>{`With `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://webassembly.org/"}}>{`WebAssembly`}</MDXTag>{` taking off, you can get the same advantage
writing Rust. Instead of pushing client-side code to the server, we push server-side code to the client by compiling Rust
to WebAssembly.`}</MDXTag>
<MDXTag name="p" components={components}>{`This `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://github.com/wasm-tool/rollup-plugin-rust"}}>{`Rollup`}</MDXTag>{`
plugin allows you to import a `}<MDXTag name="em" components={components} parentName="p">{`Cargo.toml`}</MDXTag>{` file into your
TypeScript codebase, allowing a seamless integration between
Rust and TypeScript code. The dev experience is almost as smooth
as writing TypeScript itself (for example, the browser refreshes
automatically when you change a line of Rust code).`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="a" components={components} parentName="p" props={{"href":"https://github.com/rustwasm/wasm-bindgen"}}>{`wasm-bindgen`}</MDXTag>{`
facilitates serialization of Rust structs into JSON objects
and vice-versa.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Limitations of TypeScript`}</MDXTag>
<MDXTag name="p" components={components}>{`Coding in TypeScript has been a largely pleasant experience,
but switching to Rust immediately brought to light some of the
limitations of TypeScript.`}</MDXTag>
<MDXTag name="h4" components={components}>{`Strict Typing`}</MDXTag>
<MDXTag name="p" components={components}>{`TypeScript is more of a type-hinter than a type-checker.
It primarily ensures that you’re not accessing fields that
you aren’t declaring via the type system (which is quite expressive). It also enables nicer autocomplete for your IDE.`}</MDXTag>
<MDXTag name="p" components={components}>{`However, it does not actually ensure that the data you are
manipulating corresponds to the type that you have declared
to represent it. For example, the data might contain `}<MDXTag name="strong" components={components} parentName="p">{`additional fields`}</MDXTag>{` or
even `}<MDXTag name="strong" components={components} parentName="p">{`incorrect`}</MDXTag>{` values for declared types.`}</MDXTag>
<MDXTag name="h4" components={components}>{`Data Validation`}</MDXTag>
<MDXTag name="p" components={components}>{`You have to write data validation code to ensure that you’re
operating on correct data in TypeScript. You get this for
free in Rust, which will throw an error if you parse data
that doesn’t line up with the struct that is to hold it in
memory.`}</MDXTag>
<MDXTag name="h4" components={components}>{`Error Handling`}</MDXTag>
<MDXTag name="p" components={components}>{`Rust’s `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://doc.rust-lang.org/book/ch06-02-match.html"}}>{`pattern matching`}</MDXTag>{` and `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://doc.rust-lang.org/book/ch09-02-recoverable-errors-with-result.html"}}>{`error handling`}</MDXTag>{` make it easy to
handle every code path that could result in an error, leading
to very robust code.`}</MDXTag>
<MDXTag name="p" components={components}>{`This leads to a great degree of confidence that if it compiles,
it’s probably not going to throw an error at runtime.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Performance`}</MDXTag>
<MDXTag name="h4" components={components}>{`WebAssembly is faster than JavaScript`}</MDXTag>
<MDXTag name="p" components={components}>{`The initial rewrite without any performance optimizations
is already faster than the previous TypeScript codebase.
This doesn’t matter too much yet, but
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://boardgamelab.app/"}}>{`Boardgame Lab`}</MDXTag>{` will eventually
ship with bots, so the performance will matter once
game trees need to be searched.`}</MDXTag>
<MDXTag name="h4" components={components}>{`The Rust server is leaner`}</MDXTag>
<MDXTag name="p" components={components}>{`The multiplayer server receives updates from clients via
WebSockets and then:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`Updates its copy of the game state.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`Broadcasts the client update to other clients.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`The previous server used `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://github.com/websockets/ws"}}>{`ws`}</MDXTag>{`, a popular library for NodeJS. The new version uses `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://github.com/seanmonstar/warp"}}>{`Warp`}</MDXTag>{` (Rust), which uses less memory
under the same load.`}</MDXTag>
<MDXTag name="h2" components={components}>{`Conclusion`}</MDXTag>
<MDXTag name="p" components={components}>{`Overall, I’m pretty happy with the switch. I haven’t really
encountered much resistance from the borrow checker despite
being fairly new to Rust (I come from a C++ background, so
maybe that helps).`}</MDXTag>
<MDXTag name="p" components={components}>{`discussion on `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://news.ycombinator.com/item?id=23776514"}}>{`Hacker News`}</MDXTag>{` |
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://twitter.com/search?q=https%3A%2F%2Fnicolodavis.com%2Fblog%2Ftypescript-to-rust%2F"}}>{`Twitter`}</MDXTag></MDXTag>
           </MDXTag>
  }
}

export const _frontmatter = {};

  